import React from "react"

export default () => (
    <footer>
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/press/">Press</a></li>
            <li><a href="/about/">Credits</a></li>
            <li><a href="/poster/">Poster</a></li>
            <li><a href="/contact/">Contact</a></li>
            <li><a href="https://www.facebook.com/AnExquisiteMeal/" target="_new">Facebook</a></li>
            <li><a href="https://www.instagram.com/anexquisitemeal/" target="_new">Instagram</a></li>
            
        </ul>
    </footer>
)